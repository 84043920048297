import MessageMap from '../i18n.d';

const esES: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Disponível apenas a opção em inglês'
  },
  sidebar: {
    campaigns: 'Campañas',
    audiences: 'Audiencias'
  },
  audience: {
    listPage: {
      searchPlaceholder: 'Buscar por nombre',
      audience: 'Audiencias',
      loading: 'Cargando artículos...',
      estimatedTarget: 'Objetivo estimado actual',
      createdOn: 'Creado el',
      lastUpdate: 'Se actualizó el',
      campaigns: 'Campañas',
      actions: 'Acciones',
      estimatedTargetInfo: 'Esto indica la cantidad estimada de PDV en esta audiencia.',
      lastUpdateInfo: 'This number is updated daily to reflect the estimated target audience.',
      addNew: 'Crear nueva audiencia',
      openExportedFiles: 'Abrir archivos exportados',
      update: 'Actualizar',
      itemsPerPage: 'Artículos por página: {value}',
      name: 'Nombre',
      status: 'Estado',
      statusProcessing: 'Procesando',
      statusNotProcessed: 'No procesado',
      statusProcessedWithErrors: 'Procesado con errores',
      statusProcessedSuccess: 'Procesado correctamente',
      created: {
        audienceCreated: '¡Audiencia creada!'
      },
      saved: {
        audienceSaved: '¡Audiencia guardada!'
      },
      noAudiences: {
        description: 'Tus audiencias se mostrarán aquí.',
        btnLabel: 'Crear nueva audiencia'
      },
      errorGetAudiences: {
        description: 'No pudimos cargar la lista. Intenta volver a cargarla.',
        btnLabel: 'Volver a cargar lista'
      },
      errorSearchingAudiences: {
        description: 'No se encontraron resultados. Intenta cambiar tu búsqueda.',
        btnLabel: 'Borrar búsqueda'
      }
    },
    audiencePage: {
      newAudience: 'Nueva audiencia',
      leaveModal: {
        title: '¿Abandonar la creación de audiencia?',
        body: 'Los cambios que no se guardaron se perderán.',
        leaveButton: 'Salir',
        cancelButton: 'Cancelar'
      },
      vendorModal: {
        title: '¿Cambiar proveedor?',
        body: 'Si cambias de proveedor, se perderán los cambios que no hayan sido guardados.'
      },
      countryModal: {
        title: '¿Cambiar país?',
        body: 'Si cambias de país, se perderán los cambios que no hayan sido guardados.'
      },
      sellerModal: {
        title: '¿Cambiar vendedor?',
        body: 'Si cambias de vendedor, se perderán los cambios que no hayan sido guardados.'
      },
      leaveButton: 'Sí, cambiar',
      cancelButton: 'No, volver',
      exportingFileModal: {
        titleExportingFileModal: 'Exportando archivo',
        bodyExportingFileModal:
          'Esto puede tomar algunos minutos. Ve a los archivos exportados para obtener actualizaciones de estado y descarga los archivos cuando estén listos.',
        leaveButtonExportingFileModal: 'Ir a archivos exportados',
        cancelButtonExportingFileModal: 'Cerrar'
      }
    },
    deleteAudienceModal: {
      title: 'Delete audience?',
      buttonDelete: 'Yes, delete audience',
      buttonCancel: 'No, go back',
      default: 'This action cannot be undone.',
      linked:
        'Unreleased campaigns linked to this audience will appear in an Error state, and you will need to select new audiences for them.'
    },
    exportedFilesPage: {
      title: 'Archivos exportados',
      table: {
        toolbar: {
          search: 'Buscar por nombre',
          refresh: 'Actualizar'
        },
        header: {
          audienceName: 'Nombre de la audiencia',
          type: 'Tipo',
          fileName: 'Nombre del archivo',
          status: 'Estado',
          estimatedTarget: 'Objetivo estimado',
          actions: 'Acciones'
        },
        columns: {
          types: {
            invalidPocs: 'PDV no válidos'
          },
          status: {
            processing: 'Procesando',
            done: 'Procesado',
            failed: 'Falló'
          }
        }
      }
    }
  },
  actions: {
    edit: 'Editar',
    delete: 'Borrar',
    download: 'Descargar'
  },
  sidebarCMS: {
    Home: 'Home',
    BeesCustomer: 'BEES Customer',
    BeesEngage: 'BEES Engage',
    MiNegocio: 'Mi Negocio',
    AudienceEditor: 'Audience Editor'
  },
  sidebarOffer: {
    OfferList: 'Lista de ofertas',
    AudienceBuilder: 'Generador de audiencias'
  },
  useSidebarForce: {
    Home: 'Gestión de tareas'
  },
  sidebarMembership: {
    EarningRules: 'Earning Rules',
    AudienceBuilder: 'Generador de audiencias'
  },
  sidebarDeliveryWindow: {
    DdcsList: 'Administrar DDCs',
    ClientsList: 'Ventanas de entrega',
    ImportsExports: 'Importaciones y Exportaciones',
    Autoblock: 'Parámetros de disponibilidad de capacidad de entrega',
    Audiences: 'Audiencias'
  }
};

export default esES;
