const mockUrl = (mockUrl: string) => 'https://stoplight.io/mocks/bees/bees-engage'.concat(mockUrl);

export const GET_AUDIENCES =
  '/api/audience-management-audience-business-service/v2/custom-audience/audiences';

export const GET_AUDIENCES_EU =
  '/api/eu/audience-management-audience-business-service/v2/custom-audience/audiences';

export const GET_AUDIENCE_BY_ID = `/api/audience-management-audience-business-service/v1/custom-audience/audiences/:id`;

export const GET_AUDIENCE_BY_ID_EU = `/api/eu/audience-management-audience-business-service/v1/custom-audience/audiences/:id`;

export const GET_SUPPORTED_COUNTRIES =
  '/api/audience-management-audience-business-service/countries';

export const GET_EXPORTED_FILES = '/api/audience-management-audience-business-service/v1/custom-audience/export-audiences';

export const GET_SELLERS = '/api/dlb-levers-business-service/sellers';

export const POST_EXPORT_AUDIENCE = `/api/audience-management-audience-business-service/v1/custom-audience`;

export const mockService: Record<string, string> = {
  [GET_AUDIENCES]: mockUrl('/149882098/v1/custom-audience/audiences'),
  [GET_SUPPORTED_COUNTRIES]: mockUrl('/120619849/countries')
};
