import {
  AppHeaderConfig,
  AppHeaderConfigPayload,
  useAppHeader
} from 'admin-portal-shared-services';
import { useDashboardIntl } from 'i18n';
import { useEffect } from 'react';

export type UseAppHeaderServiceProps = AppHeaderConfigPayload;
export type UseAppHeaderServiceResponse = {
  appHeaderConfig: AppHeaderConfig;
  setAppHeaderConfig: (config: AppHeaderConfigPayload) => void;
};

const useAppHeaderService = (props?: UseAppHeaderServiceProps): UseAppHeaderServiceResponse => {
  const [appHeaderConfig, setAppHeaderConfig] = useAppHeader();
  const { titlePage } = useDashboardIntl();

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle: titlePage,
      countrySelect: true,
      vendorSelect: true,
      ...props
    });
  }, [setAppHeaderConfig, props, titlePage]);

  return { appHeaderConfig, setAppHeaderConfig };
};

export default useAppHeaderService;
