import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import Loader from 'components/Loader/Loader';
import LoadingRender from 'components/LoadingRender/LoadingRender';
import useAppHeaderService from 'pages/DashboardPage/hooks/useAppHeaderService/useAppHeaderService';
import { VendorServicesResponse } from 'services/vendors/VendorServices.d';
import {
  LocalStorageVendor,
  getLocalStorageSelectedVendor,
  setLocalStorageSelectedVendor
} from 'utils/LocalStorage/hubHeaderData';
import { BeesLoading } from './BeesContext.styles';
import { useGetSupportedCountries } from './hooks/useGetSupportedCountries/useGetSupportedCountries';

interface BeesContextProps {
  countryOptions: string[];
  country: string;
  loadingScreen: boolean;
  hasVendorSelection: boolean;
  selectedVendor: LocalStorageVendor;
}
interface BeesProviderProps {
  children: ReactNode;
}

interface VendorProperties {
  id: string;
  country: string;
  isManufacturer: boolean;
  displayName: string;
  serviceModel: string;
  tier: { name: string; app: string };
}

const initState: BeesContextProps = {
  countryOptions: [],
  country: 'BR',
  hasVendorSelection: false,
  loadingScreen: false,
  selectedVendor: {
    selectedVendor: '',
    selectedCountry: '',
    abiVendorId: '',
    allowVendorSelection: false,
    isManufacturer: false,
    displayName: '',
    serviceModel: '',
    tier: { name: '', app: '' }
  }
};

export const BeesContext = createContext<BeesContextProps>(initState);

export const BeesProvider = ({ children }: BeesProviderProps) => {
  const { appHeaderConfig, setAppHeaderConfig } = useAppHeaderService();

  const { data: supportedCountriesArray, loading: loadingSupportedCountries } =
    useGetSupportedCountries();

  const [vendorState, setVendorState] = useState<LocalStorageVendor | null>(
    getLocalStorageSelectedVendor()
  );

  const updateVendorABI = useCallback(
    (vendor: VendorServicesResponse) => {
      const newVendor: LocalStorageVendor = {
        ...vendor,
        selectedVendor: vendor.abiVendorId,
        selectedCountry: vendor.code
      };
      setLocalStorageSelectedVendor(newVendor);
      setVendorState(newVendor);
      setAppHeaderConfig({
        defaultCountry: vendor.code,
        defaultVendor: vendor.abiVendorId
      });
    },
    [setAppHeaderConfig]
  );

  const getVendorFromSelectedCountry = useCallback(() => {
    return supportedCountriesArray?.find(
      (country) => country.code === appHeaderConfig.selectedCountry
    );
  }, [supportedCountriesArray, appHeaderConfig.selectedCountry]);

  const getVendorFromVendorSelector = useCallback((): LocalStorageVendor => {
    const vendor = appHeaderConfig.vendorOptions.find(
      (vendor) => vendor.id === appHeaderConfig.selectedVendor
    ) as VendorProperties;
    const abiVendorId = getVendorFromSelectedCountry()?.abiVendorId;

    return {
      abiVendorId,
      selectedVendor: vendor.id,
      selectedCountry: vendor.country,
      allowVendorSelection: true,
      isManufacturer: vendor.isManufacturer,
      displayName: vendor.displayName,
      serviceModel: vendor.serviceModel,
      tier: vendor.tier
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appHeaderConfig.selectedVendor, appHeaderConfig.vendorOptions, vendorState]);

  const hasChangedCountry = useMemo(() => {
    const hasSupportedCountries = !!supportedCountriesArray?.length;
    const hasSelectedCountry = !!appHeaderConfig.selectedCountry;
    const hasSelectedVendor = !!vendorState;
    const isCountryDifferentFromVendor =
      appHeaderConfig.selectedCountry &&
      appHeaderConfig.selectedCountry !== vendorState.selectedCountry;

    return (
      hasSupportedCountries &&
      hasSelectedCountry &&
      hasSelectedVendor &&
      isCountryDifferentFromVendor
    );
  }, [supportedCountriesArray, appHeaderConfig.selectedCountry, vendorState]);

  const vendorSelectionAllowed = useMemo(
    () => appHeaderConfig.vendorSelect,
    [appHeaderConfig.vendorSelect]
  );

  const loadingScreen = useMemo(() => {
    return loadingSupportedCountries;
  }, [loadingSupportedCountries]);

  useEffect(() => {
    if (!vendorState || vendorState.selectedVendor === '') {
      const defaultVendor = {
        selectedVendor: '',
        selectedCountry: '',
        abiVendorId: '',
        allowVendorSelection: false,
        isManufacturer: false,
        displayName: '',
        serviceModel: '',
        tier: { name: '', app: '' }
      };

      if (JSON.stringify(vendorState) !== JSON.stringify(defaultVendor)) {
        setLocalStorageSelectedVendor(defaultVendor);
        setVendorState(defaultVendor);
      }
    }
  }, [vendorState]);

  useEffect(() => {
    if (loadingScreen) return;

    const vendorLocalStorage = getLocalStorageSelectedVendor();
    const isFirstAccess = !vendorLocalStorage.selectedCountry && !vendorLocalStorage.selectedVendor;
    if (isFirstAccess && supportedCountriesArray?.length > 0) {
      updateVendorABI(supportedCountriesArray[0]);
      return;
    }

    if (hasChangedCountry || (!vendorSelectionAllowed && selectedVendorIsDifferentFromState)) {
      const vendor = getVendorFromSelectedCountry();
      updateVendorABI(vendor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingScreen, supportedCountriesArray, vendorSelectionAllowed, hasChangedCountry]);

  const selectedVendorIsDifferentFromState = useMemo(
    () =>
      appHeaderConfig.selectedVendor &&
      appHeaderConfig.selectedVendor !== vendorState.selectedVendor,
    [appHeaderConfig.selectedVendor, vendorState.selectedVendor]
  );

  useEffect(() => {
    if (loadingScreen || appHeaderConfig.loadingVendors) return;

    if (vendorSelectionAllowed && selectedVendorIsDifferentFromState) {
      const vendor = getVendorFromVendorSelector();
      setLocalStorageSelectedVendor(vendor);
      setVendorState(vendor);
    }
  }, [
    vendorSelectionAllowed,
    selectedVendorIsDifferentFromState,
    loadingScreen,
    appHeaderConfig.selectedVendor,
    getVendorFromVendorSelector,
    appHeaderConfig.loadingVendors
  ]);

  const memoValue = useMemo((): BeesContextProps => {
    const countryOptions = supportedCountriesArray?.map((country) => country.code);
    const country = vendorState.selectedCountry;
    return {
      countryOptions,
      country,
      loadingScreen,
      selectedVendor: vendorState,
      hasVendorSelection: vendorSelectionAllowed
    };
  }, [supportedCountriesArray, vendorState, loadingScreen, vendorSelectionAllowed]);

  useEffect(() => {
    if (!memoValue.countryOptions?.length) return;

    setAppHeaderConfig({
      countryOptions: memoValue.countryOptions,
      defaultCountry: memoValue.selectedVendor.selectedCountry,
      defaultVendor: memoValue.selectedVendor.selectedVendor
    });
  }, [setAppHeaderConfig, memoValue]);

  return (
    <BeesContext.Provider value={memoValue}>
      <LoadingRender
        isLoading={loadingScreen}
        fallBack={
          <BeesLoading>
            <Loader type="buzz" wrapperSize="page" />
          </BeesLoading>
        }
      >
        {children}
      </LoadingRender>
    </BeesContext.Provider>
  );
};

const useBeesProvider = (): BeesContextProps => {
  return useContext(BeesContext);
};

export default useBeesProvider;
